@font-face {
font-family: '__circular_cf3c1a';
src: url(/_next/static/media/9648e38f67e22351-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__circular_cf3c1a';
src: url(/_next/static/media/f49f0a796e65b578-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__circular_cf3c1a';
src: url(/_next/static/media/a485f18c4f1a48a0-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__circular_cf3c1a';
src: url(/_next/static/media/a485f18c4f1a48a0-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__circular_cf3c1a';
src: url(/_next/static/media/5f63646fc488e799-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__circular_Fallback_cf3c1a';src: local("Arial");ascent-override: 98.02%;descent-override: 26.35%;line-gap-override: 0.00%;size-adjust: 101.72%
}.__className_cf3c1a {font-family: '__circular_cf3c1a', '__circular_Fallback_cf3c1a'
}.__variable_cf3c1a {--font-circular: '__circular_cf3c1a', '__circular_Fallback_cf3c1a'
}

@font-face {
font-family: '__circularStd_7128ba';
src: url(/_next/static/media/666cef996dc2bd70-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__circularStd_7128ba';
src: url(/_next/static/media/84325db1dd350141-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__circularStd_7128ba';
src: url(/_next/static/media/6e03d4143383b14d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__circularStd_7128ba';
src: url(/_next/static/media/6e03d4143383b14d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__circularStd_7128ba';
src: url(/_next/static/media/14f7cbcc7d2501b3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__circularStd_Fallback_7128ba';src: local("Arial");ascent-override: 98.02%;descent-override: 26.35%;line-gap-override: 0.00%;size-adjust: 101.72%
}.__className_7128ba {font-family: '__circularStd_7128ba', '__circularStd_Fallback_7128ba'
}.__variable_7128ba {--font-circular-std: '__circularStd_7128ba', '__circularStd_Fallback_7128ba'
}

